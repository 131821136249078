<template>
  <div>
    <CommonButton type="info" @click="onClick">特定商取引12条の6に関する事項はこちら</CommonButton>
    <LazySpecifiedCommercialModal :show="enableModal" @close="onClose" />
  </div>
</template>

<script lang="ts" setup>
const enableModal = ref<boolean>(false);
const onClick = () => {
  enableModal.value = true;
};
const onClose = () => {
  enableModal.value = false;
};
</script>
